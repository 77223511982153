<template>
  <div v-if="videoIds.length > 0">
    <div v-if="!!title" class="text-right material-apoio" @click="fetchVideoData()">
      {{ title }}
      <CIcon name="cib-youtube" />
    </div>
    <div class="video-grid" v-if="loaded">
      <div v-for="videoId in videoIds" :key="videoId" class="video-container" @click="openModal(videoId)">
        <img :src="getThumbnail(videoId)" alt="Thumbnail" class="video-thumbnail" />
        <div class="video-title">{{ videoTitles[videoId] || "Carregando..." }}</div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="modalOpen" class="video-modal" @click="closeModal">
      <div class="video-modal-content" @click.stop>
        <span class="close-btn" @click="closeModal">&times;</span>
        <iframe
          width="700"
          height="394"
          :src="`https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoIds: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      videoTitles: {},
      modalOpen: false,
      selectedVideoId: null,
      videoDatas: [],
      loaded: false
    };
  },
  methods: {
    async fetchVideoData() {
      if (this.loaded) {
        return;
      }

      this.loaded = true;

      for (const videoId of this.videoIds) {
        try {
          const response = await fetch(`https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`);
          const data = await response.json();
          this.$set(this.videoTitles, videoId, data.title);
        } catch (error) {
          console.error("Erro ao buscar título do vídeo:", error);
        }
      }
    },
    getThumbnail(videoId) {
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    },
    openModal(videoId) {
      this.selectedVideoId = videoId;
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.selectedVideoId = null;
    }
  }
};
</script>

<style scoped>
.video-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  //justify-content: center;
  margin-bottom: 0.5rem;
}

.video-container {
  width: 23%;
  display: flex;
  cursor: pointer;
  border: 1px solid #d8dbe0;
  padding: 4px;
  background: #fff;
  transition: transform 0.2s;
}

.video-container:hover {
  text-decoration: underline;
}

.video-thumbnail {
  width: 100px;
  height: auto;
}

.video-title {
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
}

/* Modal */
.video-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}

.video-modal-content {
  background-color: white;
  padding: 20px;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.material-apoio {
  cursor: pointer;
}

.material-apoio:hover {
  text-decoration: underline;
}

.material-apoio .c-icon {
  color: red;
}
</style>
