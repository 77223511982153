<template>
  <div>
    <Loading
      :isActive="!contentLoaded && !loadingErrorMessage"
      message="Validando sessão ..."
    />
    <div class="c-app" v-if="contentLoaded">
      <TheSidebar
        :show="show"
        :toggleShowHide="toggleShowHide"
        :modulos="carga.modulos"
        :sistemas="sistemas"
        :loadSistemas="loadSistemas"
      />
      <CWrapper>
        <TheHeader :show="show" :toggleShowHide="toggleShowHide" />
        <div class="c-body">
          <main class="c-main">
            <CContainer fluid>
              <VideoGallery
                :video-ids="videos"
                title="Clique aqui e confira materiais de apoio sobre este módulo"
              />
              <transition name="fade" mode="out-in">
                <router-view
                  :videos="videos"
                  :carga="carga"
                  :sistemas="sistemas"
                  :loadSistemas="loadSistemas"
                  :key="$route.path"
                />
              </transition>
            </CContainer>
          </main>
        </div>
        <TheFooter />
      </CWrapper>
    </div>
    <div v-if="loadingErrorMessage">
      <CAlert show color="danger" class="text-center">
        {{ loadingErrorMessage }}
      </CAlert>
    </div>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import { get } from "../../helpers/apiRequest";
import { loadCargas } from "../../helpers/cargas";
import config from "../../config";
import VideoGallery from "@/components/video/VideoGallery.vue";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    VideoGallery,
  },
  data() {
    return {
      show: true,
      sistemas: null,
      cargaValidada: false,
      carga: {},
      loadingErrorMessage: null,
      videos: []
    };
  },
  computed: {
    contentLoaded() {
      return this.sistemas !== null && this.cargaValidada;
    },
  },
  watch: {
    $route(to, from) {
      this.loadPageVideos();
    }
  },
  methods: {
    loadPageVideos() {
      this.videos = this.$route.matched.find(route => route.props?.default)?.props?.default?.videos || [];
    },
    toggleShowHide() {
      this.show = !this.show;
    },
    loadSistemas() {
      get("/admin/sistemas")
        .then((json) => {
          let _sistemas = [];

          json.map((sistema) => {
            _sistemas[sistema.chave] = sistema;
          });

          this.sistemas = { ..._sistemas };
          this.loadingErrorMessage = null;
        })
        .catch((err) => {
          this.invalidarCarga();

          if (err.message === "Failed to fetch") {
            this.loadingErrorMessage = `Link de API não respondendo (${config.api}), tente atualizar a página, caso o problema persista, contate o suporte técnico.`;
          }

          this.sistemas = null;
        });
    },
    checkReloadCarga() {
      if (!!localStorage.getItem("revalidar_carga")) {
        loadCargas(config.token(), (carga) => {
          this.carga = carga;
          localStorage.setItem("revalidar_carga", 0);
          this.cargaValidada = true;
        });
        return;
      }

      this.cargaValidada = true;
    },
    initCarga() {
      const app = localStorage.getItem("app");
      this.carga = !!app ? JSON.parse(localStorage.getItem("app")) : {};
    },
    initWebSocket() {
      if (this.$pusher) {
        const channelGeral = this.$pusher.subscribe("geral");

        channelGeral.bind("parametro-atualizado", this.invalidarCarga);
        channelGeral.bind("perfil-atualizado", this.invalidarCarga);
      }
    },
    invalidarCarga() {
      localStorage.setItem("revalidar_carga", 1);
      this.checkReloadCarga();
    },
    checkLoggedIn() {
      const token = localStorage.getItem("token");

      if (!token) {
        this.$router.push("/login");
      } else {
        this.loadSistemas();
        this.checkReloadCarga();
        this.initWebSocket();
        this.initCarga();
        this.loadPageVideos();
      }
    },
  },
  beforeMount() {
    this.checkLoggedIn();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
